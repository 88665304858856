import React from 'react'
import { navigate } from 'gatsby'

const NotFound = () => {
  React.useEffect(() => {
    navigate('/404.html')
  })
  return (
    null
  )
}

export default NotFound
