import React, { useState } from 'react'
// import { useSelector } from 'react-redux'
import styled from 'astroturf/react'

// Internal Components
import Item from './item'

// External Components
import {
  Table,
  Header,
  HeaderCell,
  Body
} from '@calendly/ui/components/table'
import { Icon } from '@calendly/ui/components/icon'
import { Button } from '@calendly/ui/components/button'
import { navigate } from 'gatsby'

export const TableContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  hr {
    border-top: 2px solid #CCCCCC;
    border-radius: 5px;
    margin: 24px 0px 24px 0px;
  }
`

export const TableHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43px;

  h1 {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 140%;
  }
`

const AppsTable = ({ apps, onDelete }) => {
  const [values, setValues] = useState(apps)

  const onSort = (column, direction) => {
    setValues(
      values.slice().sort((a, b) => {
        const multiplier = direction === 'asc' ? 1 : -1
        return multiplier * a[column].localeCompare(b[column], undefined, { numeric: true })
      })
    )
  }

  const handleEdit = (clientId, name, environment, redirectUri, confidential) => {
    navigate('edit', {
      state: {
        clientId,
        name,
        environment,
        redirectUri,
        confidential
      }
    })
  }

  const handleDelete = (id) => {
    onDelete(id)
  }

  return (
    <TableContainer>
      <HeaderContainer>
        <TableHeader>
          <h1>My Apps</h1>
          <Button
            onClick={() => navigate('new')}
          >
            <Icon name="add" />
            Create new app
          </Button>
        </TableHeader>
        <hr />
      </HeaderContainer>
      <Table onSort={onSort}>
        <Header>
          <HeaderCell columnName="name">Name</HeaderCell>
          <HeaderCell>Created date</HeaderCell>
          <HeaderCell>Updated date</HeaderCell>
          <HeaderCell>Environment</HeaderCell>
          <HeaderCell>Redirect URI</HeaderCell>
          <HeaderCell />
        </Header>
        <Body>
          {values.map((app) => <Item key={app.clientId} sortKey={app.name} {...app} onEdit={handleEdit} onDelete={handleDelete} />)}
        </Body>
      </Table>
    </TableContainer>
  )
}

export default AppsTable
