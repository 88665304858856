import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import styled from 'astroturf/react'
import { Loader } from '@calendly/ui/components/loader'

import '../apps.css'
import emptyImage from 'assets/images/empty_console_background.svg'
import { navigate } from 'gatsby'

// Internal Components
import AppsTable from './appsTable'
import DeletePopup from '../delete/deletePopup'

// External Components
import { Icon } from '@calendly/ui/components/icon'
import { Button } from '@calendly/ui/components/button'
import { Alert } from '@calendly/ui/components/alert'

// Hooks
import { useGetAppsQuery, useDeleteAppByIdMutation } from '../appsSlice'

const CenteredLoader = styled(Loader)`
  margin: auto auto;
  height: 75vh;
`

type AlertType = { type?: 'success' | 'error', text?: string };

export const List = (_: RouteComponentProps) => {
  const { data, isError, isFetching } = useGetAppsQuery()
  const [deleteApp] = useDeleteAppByIdMutation()
  const [isOpen, setIsOpen] = useState(false)
  const [alert, setAlert] = useState<AlertType>({})
  const [deleteId, setDeleteId] = useState()

  useEffect(() => {
    if (isError) {
      setAlert({ type: 'error', text: 'Failed to fetch OAuth apps.' })
    }
  }, [isError])

  const dismissAlert = () => {
    setDeleteId(null)
    setAlert({})
  }
  const handleSetIsOpen = (isOpen) => {
    setIsOpen(isOpen)
  }

  const onDelete = (id) => {
    setDeleteId(id)
    setIsOpen(true)
  }

  const handleDelete = async () => {
    const result = await deleteApp(deleteId)
    if ('error' in result) {
      setAlert({ type: 'error', text: 'Failed to delete your app.' })
      setDeleteId(null)
    } else {
      setAlert({ type: 'success', text: 'Your app was deleted!' })
      setDeleteId(null)
    }
  }

  const renderAppsList = () => {
    return (
      <AppsTable apps={data?.myApps || []} onDelete={onDelete} />
    )
  }

  const renderEmptyAppsContent = () => {
    return (
      <div className="apps-feature">
        <div className="apps-header">
          <h1>
            My Apps
          </h1>
          <button type="button">
            Getting started
          </button>
        </div>
        <div className="apps-list">
          <div className="apps-welcome-message">
            <h1>
              Welcome to your apps portal
            </h1>
            <p>
              Get started by creating your first app and authenticating with OAuth.
            </p>
          </div>
        </div>
        <div className="apps-empty-image">
          <img alt="" src={emptyImage} />
        </div>
        <div className="apps-line" />
        <div className="apps-create">
          <div className="apps-create-messaging">
            <p>
              After you create an app, check back here to manage or create more.
            </p>
          </div>
          <Button
            type="button"
            onClick={() => navigate('new')}
          >
            <Icon name="add" />
            Create new app
          </Button>
        </div>
      </div>
    )
  }

  if (isFetching) return (<CenteredLoader data-testid="loader" />)

  return (
    <>
      <Alert type={alert.type} text={alert.text} onDismiss={dismissAlert} />
      <DeletePopup isOpen={isOpen} setIsOpen={handleSetIsOpen} onDelete={handleDelete} />
      {(data !== null && data?.myApps.length > 0) ? (
        renderAppsList()
      ) : (
        renderEmptyAppsContent()
      )}
    </>
  )
}

export default List
