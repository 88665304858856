import styled from 'astroturf/react'

export const PageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 104px;
  margin: 0 24px;
  position: relative;
  @media screen and (max-width: 1100px) {
    padding-top: 74px;
    &.hasPageIndicator {
      padding-top: 90px;
    }
  }
`

export const ContentWrapper = styled('div')<{
  fullWidth: boolean
  noPadding: boolean
  hasSidebar: boolean
}>`
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  padding-top: 24px;

  &.hasSidebar {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 1280px) {
      flex-direction: column;
      padding-top: 0;
    }
  }

  &.noPadding {
    padding-top: 0;
  }

  &.fullWidth {
    max-width: initial;
  }
`

export const ContentBody = styled('div')``
