import React, { useEffect } from 'react'

// Utils
import { initializeSegment, reinitializeOneTrust } from 'src/util/gdpr'

// Layout
import { Header } from '../navigation/header'
import { Footer } from '../navigation/footer'
import { Sidebar } from '../navigation/sidebar'

// Components
import {
  PageContainer,
  ContentWrapper,
  ContentBody
} from './app-styles'

import { setUsabillaPageViews, Usabilla } from '../external/usabilla'
import MobileBreadcrumbs from '../navigation/breadcrums/MobileBreadcrumbs'

type NavigationItem = {
  title: string
  page: {
    slug: string
  }
  subNavigationItems: NavigationItem[]
  hasSidebar: boolean
}
export const AppLayout = (props) => {
  const {
    footerColumns,
    children,
    hasSidebar,
    noFooter,
    fullWidth,
    permanentHeaderShadow,
    padding,
    navigationItems
  } = props

  useEffect(() => {
    initializeSegment()
  }, [])

  useEffect(() => {
    // OneTrust Cookie Settings loses its click event handler on route changes
    // This adds it back
    reinitializeOneTrust()
  }, [children])

  useEffect(() => {
    setUsabillaPageViews()
  }, [])

  const navigation = navigationItems?.map((item: NavigationItem) => ({
    title: item.title,
    url: item.page.slug,
    items: item.subNavigationItems?.map((subItems) => ({
      title: subItems.title,
      url: subItems.page.slug
    }))
  }))

  return (
    <PageContainer>
      <Header
        activePage={null}
        permanentShadow={permanentHeaderShadow}
      />
      <ContentWrapper
        fullWidth={fullWidth}
        noPadding={padding ? padding[0] === 'No padding' : false}
        hasSidebar={hasSidebar}
      >
        {hasSidebar && (
          <Sidebar
            sidebarItems={navigation}
            activePage={null}
            meta={null}
          />
        )}
        <MobileBreadcrumbs location={null} navigation={navigation} />
        <ContentBody>
          {children}
        </ContentBody>
      </ContentWrapper>
      {!noFooter && (
        <Footer columns={footerColumns} />
      )}
      <Usabilla />
    </PageContainer>
  )
}
