import React, { useEffect, useState } from 'react'
import styled from 'astroturf/react'
import { navigate } from 'gatsby'

import { Alert } from '@calendly/ui/components/alert'
import { HeadingText, BodyText } from '@calendly/ui/components/typography'
import { useDeviceType } from '@calendly/ui/hooks/use-device-type'
const colorError = 'var(--color-error, #C84545)'
const greyLevel5 = 'var(--color-grey-3, #CCCCCC)'
const primaryColor = 'var(--primary-color, rgb(0, 105, 255))'
const containerBackgroundColor = 'var(--container-background-color, #ffffff)'
import { Fieldset, FieldLabel } from '@calendly/ui/components/form'
import { RadioField } from '@calendly/ui/components/form/radio-field'
import { TextInput } from '@calendly/ui/components/text-input'
import { Button } from '@calendly/ui/components/button'

const Rows = styled('div')`
  flex-direction: column;
`

const Bar = styled('div')`
  position: absolute;
  top: -150%;
  width: 50%;
  height: 40px;
  background: ${primaryColor};

  &:first-child {
    box-shadow: -2px 0 0 0 ${primaryColor};
  }

  &:not(:first-child) {
    border-left: 1px solid ${containerBackgroundColor};
  }
`

const ErrorText = styled(BodyText)`
  padding-top: 4px;
  color: ${colorError}
`

const ProgressWrapper = styled('div').attrs({
  isTabletDown: useDeviceType('isTabletDown')
})`
  position: relative;
  overflow: hidden;
  width: 180px;
  height: 13px;
  border: 1px solid ${greyLevel5};
  border-radius: 8px;
  background: ${containerBackgroundColor};

  ${Bar}:nth-child(1) {
    left: 0;
    transform: translateX(-5px) rotate(21deg);
  }
  ${Bar}:nth-child(2) {
    left: 50%;
    transform: translateX(-50%) rotate(21deg);
  }

  &.isTabletDown {
    width: 85px;

    ${Bar} {
      border-left: none;

      &:nth-child(1) { transform: rotate(11deg); }
      &:nth-child(2) { transform: translateX(-50%) rotate(11deg); }
    }
  }
`

const CustomFieldset = styled(Fieldset)`
  padding-top:25px;
`

const Spacer = styled('div')`
  padding-top: 8px;
`

const RightAlignContainer = styled('div')`
  float: right;
`

const CustomButton = styled(Button)`
  margin-left: 8px;
`

const CustomTip = styled('div')`
  line-height: 120%;
`

type AlertType = { type?: 'success' | 'error', text?: string };

type StepOneProps = {
  name?: string,
  setName: (s: string) => void,
  error?: Record<string, string>,
  onSave: (appKind: string, environment: string, redirectURL: string) => void
}

const StepOne = ({
  onSave,
  setName,
  name = null,
  error = null
}: StepOneProps) => {
  const kindProtip =
  (
    <CustomTip>
      <div>
        <b>Web </b>
        - server-side applications where authentication and tokens are handled on the server
      </div>
      <div>
        <b>Native </b>
        - client-side applications (SPAs, desktop or mobile apps) where authentication and tokens are handled on the client
      </div>
    </CustomTip>
  )
  const [alert, setAlert] = useState<AlertType>({})
  const [nameError, setNameError] = useState<string>()
  const [appKind, setAppKind] = useState<string>('web')
  const [environment, setEnvironment] = useState<string>('Sandbox')
  const [redirectURL, setRedirectURL] = useState<string>()
  const [redirectURLError, setRedirectURLError] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (error) {
      error.redirectUri ? setRedirectURLError(`Redirect URI ${error.redirectUri}`) : setRedirectURLError('')
      error.name ? setNameError(`App name ${error.name}`) : setNameError('')
      error.base ? setAlert({ type: 'error', text: error.base }) : setAlert({ type: 'success', text: '' })
      setIsLoading(false)
    }
  }, [error])

  const handleClick = () => {
    setRedirectURLError('')
    setNameError('')

    setIsLoading(true)
    onSave(appKind, environment, redirectURL)
  }

  return (
    <>
      <Rows>
        <Alert type={alert.type} text={alert.text} onDismiss={() => setAlert({})} />
        <HeadingText level={3}>My Apps</HeadingText>
        <Spacer />
        <HeadingText level={1}>Create Oauth App</HeadingText>
        <Spacer />
        <Spacer />
        <BodyText color="muted">Step 1 of 2</BodyText>
        <ProgressWrapper
          data-testid="progress-bar"
          role="progressbar"
        >
          <Bar
            key="bar-1"
            data-testid="progress-bar-1"
          />
        </ProgressWrapper>
        <CustomFieldset>
          <FieldLabel
            isRequired
            text="Name of app"
          />
          <TextInput
            placeholder="App test 01"
            aria-label="Name of app"
            aria-invalid={!!nameError}
            onChange={(event) => setName(event.target.value)}
          />
          {nameError && <ErrorText color="error">{nameError}</ErrorText>}
        </CustomFieldset>
        <CustomFieldset>
          <FieldLabel
            isRequired
            proTip={kindProtip}
            text="Kind of app"
          />
          <RadioField
            defaultChecked
            label="Web"
            name="appKind"
            value="web"
            onChange={() => setAppKind('web')}
          />
          <RadioField
            label="Native"
            name="appKind"
            value="native"
            onChange={() => setAppKind('native')}
          />
        </CustomFieldset>
        <CustomFieldset>
          <FieldLabel
            isRequired
            text="Environment type"
          />
          <RadioField
            defaultChecked
            label="Sandbox"
            name="environment"
            value="Sandbox"
            onChange={() => setEnvironment('Sandbox')}
          />
          <RadioField
            label="Production"
            name="environment"
            value="Production"
            onChange={() => setEnvironment('Production')}
          />
        </CustomFieldset>
        <CustomFieldset>
          <FieldLabel
            isRequired
            text="Redirect URI"
          />
          <BodyText>
            Example: https://app.example.com/auth. For more information on Redirect URIs, read&nbsp;
            <a
              className="redirect-uri-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.oauth.com/oauth2-servers/redirect-uris/"
            >
              this article
            </a>
            .
          </BodyText>
          <Spacer />
          <TextInput
            placeholder="Type URI..."
            aria-label="Redirect URI"
            aria-invalid={!!redirectURLError}
            onChange={(event) => setRedirectURL(event.target.value)}
          />
          {redirectURLError && <ErrorText color="error">{redirectURLError}</ErrorText>}
        </CustomFieldset>
      </Rows>
      <RightAlignContainer>
        <Spacer />
        <Spacer />
        <Spacer />
        <Spacer />
        <Button decoration="ghost" onClick={() => navigate('/console/apps')}>Cancel</Button>
        <CustomButton
          onClick={handleClick}
          loading={isLoading}
        >
          Save & Continue
        </CustomButton>
      </RightAlignContainer>
    </>
  )
}

StepOne.defaultProps = {
  name: null,
  error: null
}

export default StepOne
