import React from 'react'
import styled from 'astroturf/react'
import { Loader } from '@calendly/ui/components/loader'
import { useGetUserQuery } from 'src/features/current-user/userSlice'

const CenteredLoader = styled(Loader)`
  margin: auto auto;
  height: 75vh;
`

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { isError, isFetching } = useGetUserQuery('routing', { refetchOnMountOrArgChange: 1 })

  if (isFetching) return (<CenteredLoader data-testid="loader" />)

  if (isError) {
    window.location.assign(`${process.env.GATSBY_BACKEND_URL}/login?origin=${window.location.origin}`)
    return (<CenteredLoader data-testid="loader" />)
  }

  return (<Component {...rest} />)
}

export default PrivateRoute
