import React, { useEffect } from 'react'
import { Router } from '@reach/router'
import { AppLayout } from 'src/components/layout/app-layout'
import { PrivateRoute } from 'src/components/router'
import { Apps } from 'src/features/apps'
import { NotFound } from 'src/features/not-found'

// Client-side app inside of a static site
const Console = () => {
  useEffect(() => {
    document.title = 'My Apps'
  }, [])

  const location = {
    pathname: '/console'
  }

  return (
    <AppLayout
      location={location}
      noFooter
    >
      <Router basepath="/console">
        <NotFound default />
        <PrivateRoute path="/apps/*" component={Apps} />
      </Router>
    </AppLayout>
  )
}

export default Console
