import { RouteComponentProps } from '@reach/router'
import React, { useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'astroturf/react'
import { Alert } from '@calendly/ui/components/alert'
import { HeadingText, BodyText } from '@calendly/ui/components/typography'
import { Fieldset, FieldLabel } from '@calendly/ui/components/form'
import { TextInput } from '@calendly/ui/components/text-input'
import { RadioField } from '@calendly/ui/components/form/radio-field'
import { ComponentGroup } from '@calendly/ui/components/component-group'
const colorError = 'var(--color-error, #C84545)'
const colorGrey2 = 'var(--color-grey-2, #B2B2B2)'
import { Button } from '@calendly/ui/components/button'
import { useUpdateAppMutation } from '../appsSlice'

const Rows = styled('div')`
  flex-direction: column;
`
const Spacer = styled('div')`
  padding-top: 8px;
`
const CustomFieldset = styled(Fieldset)`
  padding-top:25px;
`
const FlexContainer = styled('div')`
  display: flex;
  width: 100%;
  padding-top: 8px;

  > input {
    flex: auto;
  }
`
const CustomInput = styled(TextInput)`
  flex: auto;
`
const ErrorText = styled(BodyText)`
  padding-top: 4px;
  color: ${colorError}
`
const CustomButton = styled(Button)`
  border: 1px solid var(--color-grey-2, ${colorGrey2});
  border-radius: 8px;
`

const RightAlignContainer = styled('div')`
  float: right;
`

type AlertType = { type?: 'success' | 'error', text?: string }
type Errors = { title: string, message: string, details: Array<{message: string, parameter: string}> }

const EditApp = (props: RouteComponentProps) => {
  const kindProtip = `Web - server-side applications where authentication and tokens are handled on the server Native -
  client-side applications (SPAs, desktop or mobile apps) where authentication and tokens are handled on the client`
  const [alert, setAlert] = useState<AlertType>({})
  const [nameError, setNameError] = useState<string>()
  const [appKind, setAppKind] = useState<boolean>(true)
  const [newRedirectURL, setRedirectURL] = useState<string>('')
  const [newEnvironment, setEnvironment] = useState<string>('Sandbox')
  const [redirectURLError, setRedirectURLError] = useState<string>()
  const [newName, setName] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [updateApp, { data, isSuccess, error }] = useUpdateAppMutation()

  const { location } = props
  const { state }: { state: any } = location
  const {
    clientId,
    name,
    environment,
    redirectUri,
    confidential
  }: { clientId: string, name: string, environment: string, redirectUri: string, confidential: boolean } = state

  React.useEffect(() => {
    setName(name)
    setRedirectURL(redirectUri)
    setEnvironment(environment)
    setAppKind(confidential)
  }, [name, redirectUri, environment, confidential])

  React.useEffect(() => {
    if (error) {
      const errors = handleErrors(error.data)
      errors.redirectUri ? setRedirectURLError(`Redirect URI ${errors.redirectUri}`) : setRedirectURLError('')
      errors.name ? setNameError(`App name ${errors.name}`) : setNameError('')
      errors.base ? setAlert({ type: 'error', text: errors.base }) : setAlert({ type: 'success', text: '' })
      setIsLoading(false)
    }
  }, [error])

  React.useEffect(() => {
    if (data && isSuccess) navigate('/console/apps')
  }, [data, isSuccess])

  const handleErrors = ({ details } : Errors) => {
    const errors: Record<string, string> = {}
    const baseError = details?.find((error) => error.parameter === 'base')
    const redirectUriError = details?.find((error) => error.parameter === 'redirect_uri')
    const nameError = details?.find((error) => error.parameter === 'name')

    if (redirectUriError) errors.redirectUri = redirectUriError.message
    if (nameError) errors.name = nameError.message
    if (baseError) errors.base = baseError.message

    return errors
  }

  const handleOnSave = async () => {
    await updateApp(
      {
        clientId,
        confidential: appKind,
        description: 'oauth_app',
        environment: newEnvironment,
        name: newName,
        redirectUri: newRedirectURL
      }
    )

    if (data) navigate('/console/apps')
  }

  const handleClick = () => {
    setRedirectURLError('')
    setNameError('')

    setIsLoading(true)
    handleOnSave()
  }

  return (
    <Rows>
      <Alert type={alert.type} text={alert.text} onDismiss={() => setAlert({})} />
      <HeadingText level={3}>My Apps</HeadingText>
      <Spacer />
      <HeadingText level={1}>Edit Oauth App</HeadingText>
      <Spacer />
      <Spacer />
      <CustomFieldset>
        <FieldLabel
          isRequired
          text="Name of app"
        />
        <TextInput
          placeholder="App test 01"
          value={newName}
          aria-label="Name of app"
          aria-invalid={!!nameError}
          onChange={(event) => setName(event.target.value)}
        />
        {nameError && <ErrorText color="error">{nameError}</ErrorText>}
      </CustomFieldset>
      <CustomFieldset>
        <FieldLabel
          isRequired
          proTip={kindProtip}
          text="Kind of app"
        />
        <RadioField
          label="Web"
          name="appKind"
          value="web"
          checked={appKind}
          onChange={() => setAppKind(true)}
        />
        <RadioField
          label="Native"
          name="appKind"
          value="native"
          checked={!appKind}
          onChange={() => setAppKind(false)}
        />
      </CustomFieldset>
      <CustomFieldset>
        <FieldLabel
          isRequired
          text="Environment type"
        />
        <RadioField
          label="Sandbox"
          name="environment"
          value="Sandbox"
          checked={newEnvironment === 'Sandbox'}
          onChange={() => setEnvironment('Sandbox')}
        />
        <RadioField
          label="Production"
          name="environment"
          value="Production"
          checked={newEnvironment === 'Production'}
          onChange={() => setEnvironment('Production')}
        />
      </CustomFieldset>
      <CustomFieldset>
        <FieldLabel
          isRequired
          text="Redirect URI"
        />
        <BodyText>
          Example: https://app.example.com/auth. For more information on Redirect URIs, read&nbsp;
          <a
            className="redirect-uri-link"
            target="_blank"
            rel="noreferrer"
            href="https://www.oauth.com/oauth2-servers/redirect-uris/"
          >
            this article
          </a>
          .
        </BodyText>
        <Spacer />
        <TextInput
          placeholder="Type URI..."
          value={newRedirectURL}
          aria-label="Redirect URI"
          aria-invalid={!!redirectURLError}
          onChange={(event) => setRedirectURL(event.target.value)}
        />
        {redirectURLError && <ErrorText color="error">{redirectURLError}</ErrorText>}
      </CustomFieldset>
      <CustomFieldset>
        <FieldLabel
          text="Client ID"
        />
        <BodyText>Your app&apos;s unique identifier that&apos;s used to initiate OAuth.</BodyText>
        <ComponentGroup>
          <FlexContainer>
            <CustomInput value={clientId} aria-label="Client ID" readOnly />
            <CustomButton decoration="minimal" onClick={() => { navigator.clipboard.writeText(clientId) }}>Copy</CustomButton>
          </FlexContainer>
        </ComponentGroup>
      </CustomFieldset>
      <RightAlignContainer>
        <Spacer />
        <Spacer />
        <Spacer />
        <Spacer />
        <Button decoration="ghost" onClick={() => navigate('/console/apps')}>Cancel</Button>
        <CustomButton
          onClick={handleClick}
          loading={isLoading}
        >
          Save
        </CustomButton>
      </RightAlignContainer>
    </Rows>
  )
}

export default EditApp
