import React from 'react'
import { format, parseISO } from 'date-fns'
import ItemActions from './itemActions'

// External components
import { Row, Cell } from '@calendly/ui/components/table'
import { BodyText } from '@calendly/ui/components/typography'

const Item = ({
  sortKey,
  clientId,
  name,
  createdAt,
  updatedAt,
  environment,
  redirectUri,
  confidential,
  onEdit,
  onDelete
}) => (
  <Row key={sortKey}>
    <Cell><BodyText weight="bold">{name}</BodyText></Cell>
    <Cell>{format(parseISO(createdAt), 'PPP')}</Cell>
    <Cell>{format(parseISO(updatedAt), 'PPP')}</Cell>
    <Cell>{environment}</Cell>
    <Cell>{redirectUri}</Cell>
    <Cell><ItemActions clientId={clientId} onEdit={() => onEdit(clientId, name, environment, redirectUri, confidential)} onDelete={onDelete} /></Cell>
  </Row>
)

export default Item
