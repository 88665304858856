import React from 'react'
import { Router } from '@reach/router'

import List from './list/list'
import NewAppWizard from './new/newAppWizard'
import EditApp from './edit/editApp'

export const Apps = () => (
  <Router>
    <List path="/" />
    <NewAppWizard path="new" />
    <EditApp path="edit" />
  </Router>
)
