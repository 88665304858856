import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import styled from 'astroturf/react'

import StepOne from './stepOne'
import StepTwo from './stepTwo'

import { useCreateAppMutation } from '../appsSlice'

const Container = styled('div')`
  margin:0 auto;
  width: 60%;
`
type Errors = { title: string, message: string, details: Array<{message: string, parameter: string}> }

const NewAppWizard = (_: RouteComponentProps) => {
  const [name, setName] = useState<string>()

  const [createApp, { data, isSuccess, error }] = useCreateAppMutation()

  const handleOnSave = async (appKind: string, environment: string, redirectURL: string) => {
    await createApp(
      {
        confidential: appKind === 'web',
        description: 'oauth_app',
        environment,
        name,
        redirectUri: redirectURL
      }
    )
  }

  const handleErrors = ({ details } : Errors) => {
    const errors: Record<string, string> = {}
    const baseError = details?.find((error) => error.parameter === 'base')
    const redirectUriError = details?.find((error) => error.parameter === 'redirect_uri')
    const nameError = details?.find((error) => error.parameter === 'name')

    if (redirectUriError) errors.redirectUri = redirectUriError.message
    if (nameError) errors.name = nameError.message
    if (baseError) errors.base = baseError.message

    return errors
  }

  return (
    <Container>
      {!isSuccess && (
        <StepOne
          onSave={handleOnSave}
          setName={setName}
          name={name}
          error={error ? handleErrors(error.data) : null}
        />
      )}
      {isSuccess && (
        <StepTwo
          appName={name}
          clientID={data?.resource.client_id}
          clientSecret={data?.resource.client_secret}
          webhookSigningKey={data?.resource.webhook_signing_key}
        />
      )}
    </Container>
  )
}

export default NewAppWizard
