import React from 'react'
import styled from 'astroturf/react'

// External Components
import { BareButton } from '@calendly/ui/components/bare-button'
import { Icon } from '@calendly/ui/components/icon'
import { useDropdown } from '@calendly/ui/components/dropdown'
import { PopupDropdownMenu, Item } from '@calendly/ui/components/menu'

export const OptionsContainer = styled('div')`
  & span {
    padding: 12px;
  }
  & span:hover {
    border-radius: 6px;
    background-color: #F8F8F8;
  }
`

const ItemActions = ({ clientId, onEdit, onDelete }) => {
  const [toggleDropdown, Wrapper] = useDropdown()

  return (
    <>
      <BareButton onClick={toggleDropdown}>
        <OptionsContainer>
          <Icon name="overflow" />
        </OptionsContainer>
      </BareButton>
      <Wrapper>
        <PopupDropdownMenu>
          <Item onClick={onEdit}>
            <Icon name="edit" />
            Edit
          </Item>
          <Item onClick={() => onDelete(clientId)}>
            <Icon name="trashcan-fill" />
            Delete
          </Item>
        </PopupDropdownMenu>
      </Wrapper>
    </>
  )
}

export default ItemActions
