import React from 'react'

// External Components
import { SimplePopup } from '@calendly/ui/components/popup'

const DeletePopup = ({ isOpen, setIsOpen, onDelete }) => {
  const onConfirm = () => {
    setIsOpen(false)
    onDelete()
  }

  const onClose = () => setIsOpen(false)

  return (
    <SimplePopup
      isOpen={isOpen}
      isDestructive
      title="Are you sure you want to delete your app?"
      cancelLabel="Cancel"
      confirmLabel="Yes"
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <div>
        This action cannot be undone.
      </div>
    </SimplePopup>
  )
}

export default DeletePopup
