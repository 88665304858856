import React from 'react'
import styled from 'astroturf/react'

import { HeadingText, BodyText } from '@calendly/ui/components/typography'
const greyLevel5 = 'var(--color-grey-3, #CCCCCC)'
const primaryColor = 'var(--primary-color, rgb(0, 105, 255))'
const colorGrey2 = 'var(--color-grey-2, #B2B2B2)'
import { Fieldset, FieldLabel } from '@calendly/ui/components/form'
import { TextInput } from '@calendly/ui/components/text-input'
import { Button } from '@calendly/ui/components/button'
import { ComponentGroup } from '@calendly/ui/components/component-group'
import { navigate } from 'gatsby'

const Rows = styled('div')`
  flex-direction: column;
`

const FlexContainer = styled('div')`
  display: flex;
  width: 100%;
  padding-top: 8px;

  > input {
    flex: auto;
  }
`

const CustomInput = styled(TextInput)`
  flex: auto;
`

const Bar = styled('div')`
  width: 180px;
	height:13px;
	border: 1px solid ${greyLevel5};
  border-radius: 8px;
  background: ${primaryColor};
`

const CustomFieldset = styled(Fieldset)`
  padding-top:25px;
`

const Spacer = styled('div')`
  padding-top: 8px;
`

const RightAlignContainer = styled('div')`
  float: right;
`

const CustomButton = styled(Button)`
  border: 1px solid var(--color-grey-2, ${colorGrey2});
  border-radius: 8px;
`

type StepTwoProps = {
  appName: string,
  clientID: string,
  clientSecret: string,
  webhookSigningKey: string
}

const StepTwo = ({
  appName,
  clientID,
  clientSecret,
  webhookSigningKey
}: StepTwoProps) => {
  const instructions = `To authenticate your app, copy the Client ID and Client Secret. The Client Secret will not be available again once you leave this page, you will need to create a new OAuth app.
    To copy the Client ID in the future, you can copy it from edit app page.`
  return (
    <>
      <Rows>
        <HeadingText level={3}>My Apps</HeadingText>
        <Spacer />
        <HeadingText level={1}>
          {appName}
          &nbsp;was created
        </HeadingText>
        <Spacer />
        <Spacer />
        <BodyText color="muted">Step 2 of 2</BodyText>
        <Bar
          key="bar-1"
          data-testid="progress-bar-1"
        />
        <Spacer />
        <Spacer />
        <BodyText>{instructions}</BodyText>
        <Spacer />
        <CustomFieldset>
          <FieldLabel
            text="Client ID"
          />
          <BodyText>Your app&apos;s unique identifier that&apos;s used to initiate OAuth.</BodyText>
          <ComponentGroup>
            <FlexContainer>
              <CustomInput value={clientID} aria-label="Client ID" readOnly />
              <CustomButton decoration="minimal" onClick={() => { navigator.clipboard.writeText(clientID) }}>Copy</CustomButton>
            </FlexContainer>
          </ComponentGroup>
        </CustomFieldset>
        <CustomFieldset>
          <FieldLabel
            text="Client secret"
          />
          <BodyText>
            A secret that&apos;s shared between your app and Calendly&apos;s authorization server that&apos;s used to establish
            and refresh OAuth authentication.
          </BodyText>
          <ComponentGroup>
            <FlexContainer>
              <CustomInput value={clientSecret} type="password" aria-label="Client Secret" readOnly />
              <CustomButton decoration="minimal" onClick={() => { navigator.clipboard.writeText(clientSecret) }}>Copy</CustomButton>
            </FlexContainer>
          </ComponentGroup>
        </CustomFieldset>
        <CustomFieldset>
          <FieldLabel
            text="Webhook signing key"
          />
          <BodyText>
            A unique key shared between your app and Calendly that&apos;s used to verify events sent to your endpoints.
          </BodyText>
          <ComponentGroup>
            <FlexContainer>
              <CustomInput value={webhookSigningKey} type="password" aria-label="Webhook Signing Key" readOnly />
              <CustomButton decoration="minimal" onClick={() => { navigator.clipboard.writeText(webhookSigningKey) }}>Copy</CustomButton>
            </FlexContainer>
          </ComponentGroup>
        </CustomFieldset>
      </Rows>
      <RightAlignContainer>
        <Spacer />
        <Spacer />
        <Spacer />
        <Spacer />
        <Button
          onClick={() => navigate('/console/apps')}
        >
          Return to My Apps
        </Button>
      </RightAlignContainer>
    </>
  )
}

export default StepTwo
